.overlay {
  position: fixed;
  left: 50%;
  top: 10%;
  z-index: 21;

  max-width: 35rem;
  width: 95vw;

  background-color: #eee;
  border-radius: 6px;

  transform: translateX(-50%);
}

.overlay-slide-in {
  animation: slideIn 0.25s ease-out forwards;
}

.overlay-fadeout {
  animation: fadeOut 0.15s ease-out forwards;
}

@keyframes slideIn {
  from {
    top: -20%;
  }

  to {
    top: 10%;
  }
}

@keyframes fadeOut {
  from {
    opacity: 100%;
  }

  to {
    opacity: 0%;
  }
}

.header__container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--pad-modal-sml);

  background-color: var(--color-red);

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.content__container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--pad-sml);
}

.content__container p {
  font-size: var(--text-size-med);
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #333;
}

.btn__container {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  padding: var(--pad-sml);
}

.btn__container button {
  font-weight: 600;
  color: #eee;

  padding: calc(var(--pad-sml) * 0.4) calc(var(--pad-sml) * 1.25);
  border: none;
  border-radius: 4px;
  background-color: var(--color-red-shade);

  cursor: pointer;
  transition: all 0.3s;
}

@media (hover: hover) {
  .btn__container button:hover {
    background-color: #fff;
    background-color: var(--color-red-shade-highlight);
  }
}
