.content__container {
  width: 100%;

  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-med);

  padding: var(--pad-modal);
}
