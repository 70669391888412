.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.975);

  width: 100vw;
  height: 100vh;

  z-index: 5;
}

.overlay__container {
  position: fixed;
  inset: 0;

  width: 100%;
  height: 100vh;

  background-color: transparent;

  display: flex;
  flex-direction: column;

  overflow-y: auto;

  z-index: 6;
}

.loading__container {
  position: fixed;
  inset: 0;

  width: 100%;
  height: 100vh;

  background-color: #99999980;
}

.desc_loading__container {
  width: 100%;
}

.spinner__container {
  height: 100%;
}

.main-image__container {
  position: relative;

  width: 100%;
  min-height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.img__container {
  height: 100%;
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-sml);

  border-radius: 10px;

  padding: var(--pad-med) 0;

  overflow: hidden;
}

.img__wrapper {
  height: 92.5%;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.img__wrapper img {
  max-width: 100%;
  max-height: 100%;

  /* height: 100%; */
  /* width: auto; */

  border-radius: 4px;
}

.img-description {
  display: flex;

  align-items: center;
  justify-content: center;

  padding: calc(var(--pad-sml) * 0.5) var(--pad-sml);

  background-color: #000;
}

.img-description p {
  color: #eee;
  font-size: var(--text-size-sml);
  letter-spacing: 1.06px;
  line-height: 2.4rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.icon__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.icon__wrapper_close {
  position: absolute;

  top: 0;
  right: 0;
}

.icon__wrapper_info {
  position: absolute;

  bottom: 0;
  right: 0;
}

.icon {
  width: calc(var(--text-size-lrg) * 2.25);
  height: calc(var(--text-size-lrg) * 2.25);

  color: #eee;

  transition: all 0.3s;

  pointer-events: none;
}

.icon__wrapper_info {
  width: calc(var(--text-size-lrg) * 2.25);
  height: calc(var(--text-size-lrg) * 2.25);

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon__wrapper_info-disabled {
  pointer-events: none;
}

.icon__wrapper_info-disabled .icon {
  opacity: 0.4;
}

.icon__wrapper_info .icon {
  width: calc(var(--text-size-lrg) * 1.35);
  height: calc(var(--text-size-lrg) * 1.35);
}

@media (hover: hover) {
  .icon__wrapper:hover .icon {
    color: #fff;
  }
}

.icons__container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding-bottom: var(--pad-sml);
}

@media (max-width: 640px) {
  .main-image__container {
    flex-direction: column;
  }

  .img__container {
    width: 100%;

    padding: 0 var(--pad-sml);
    padding-top: var(--pad-sml);
  }

  .icon__wrapper_close {
    position: static;
  }

  .icon__wrapper_info {
    position: relative;
  }

  .icon__wrapper_info .icon {
    width: calc(var(--text-size-lrg) * 1.75);
    height: calc(var(--text-size-lrg) * 1.75);
  }
}

.desc__container {
  width: 100%;

  position: relative;

  background-color: #fffbeb;

  /* margin-bottom: var(--margin-med); */
  padding: var(--pad-lrg);
  padding-top: calc(var(--pad-lrg) * 2);
  padding-bottom: calc(var(--pad-lrg) * 4);
  gap: var(--gap-med);

  display: flex;
}

.adc_content__container {
  flex: 1;
}

.adc_content__container img {
  max-width: 100%;
  height: auto;

  border-radius: 6px;
}

.icon__wrapper_up-hidden {
  width: calc(var(--text-size-lrg) * 2.25);
  height: calc(var(--text-size-lrg) * 2.25);

  pointer-events: none;
}

.icon__wrapper_up-hidden .icon {
  color: transparent;
}

.icon__wrapper_up {
  position: sticky;

  width: calc(var(--text-size-lrg) * 2.25);
  height: calc(var(--text-size-lrg) * 2.25);

  top: 0;
}

.icon__wrapper_up .icon {
  width: calc(var(--text-size-lrg) * 2);
  height: calc(var(--text-size-lrg) * 2);

  color: #333;

  transition: all 0.3s;

  pointer-events: none;
}

@media (hover: hover) {
  .icon__wrapper_up:hover .icon {
    color: #666;
  }
}

@media (max-width: 1024px) {
  .desc__container {
    padding: var(--pad-med);
    padding-top: calc(var(--pad-lrg) * 1.5);
    padding-bottom: calc(var(--pad-lrg) * 4);

    gap: var(--gap-sml);
  }
}

@media (max-width: 768px) {
  .desc__container {
    padding: var(--pad-sml);
    padding-top: calc(var(--pad-lrg) * 1);
    padding-bottom: calc(var(--pad-lrg) * 5);
  }
}

@media (max-width: 475px) {
  .desc__container {
    padding: calc(var(--pad-sml) * 0.25);
    padding-top: calc(var(--pad-lrg) * 0.75);
    padding-bottom: calc(var(--pad-lrg) * 5);
  }
}
