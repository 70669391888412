.no-select {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.custom_scrollbar::-webkit-scrollbar {
  width: 6px;
}

.custom_scrollbar::-webkit-scrollbar-track {
  background-color: #333;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

.custom_scrollbar::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 100vw;
}

@media (hover: hover) {
  .custom_scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
  }
}

.custom_scrollbar__dark::-webkit-scrollbar-track {
  background-color: #999;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

.custom_scrollbar__dark::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 100vw;
}

@media (hover: hover) {
  .custom_scrollbar__dark::-webkit-scrollbar-thumb:hover {
    background-color: #222;
  }
}

.tooltip:not(.overwrite-style) {
  position: absolute;

  left: 50%;
  top: 150%;

  transform: translateX(-50%);

  background-color: #333;
  padding: calc(var(--pad-sml) * 0.3) var(--pad-sml);
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #fff;

  font-size: var(--text-size-sml);
  letter-spacing: 1.05px;
  color: #eee;
  white-space: nowrap;

  pointer-events: none;
}

.tooltip:not(.overwrite-style)::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;

  transform: translateX(-50%);

  border-width: calc(var(--text-size-sml) * 0.4);
  border-style: solid;
  border-color: transparent transparent black transparent;
}
