.not-found {
  width: 100vw;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--pad-lrg);

  font-size: calc(var(--text-size-lrg) * 2);
  font-weight: 700;
  color: #333;
  letter-spacing: 1.1px;
}
