.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;

  background-color: rgba(0, 0, 0, 0.975);

  width: 100vw;
  height: 100vh;

  z-index: 5;
}

.backdrop-open {
  animation: openBackdrop 0.15s linear forwards;
}

.backdrop-close {
  animation: closeBackdrop 0.15s linear forwards;
}

@keyframes openBackdrop {
  from {
    background-color: rgba(0, 0, 0, 0);
  }

  to {
    background-color: rgba(0, 0, 0, 0.95);
  }
}

@keyframes closeBackdrop {
  from {
    background-color: rgba(0, 0, 0, 0.95);
  }

  to {
    background-color: rgba(0, 0, 0, 0);
  }
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;

  z-index: 6;
}

.overlay-open {
  animation: openOverlay 0.15s linear forwards;
}

.overlay-close {
  animation: closeOverlay 0.15s linear forwards;
}

@keyframes openOverlay {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes closeOverlay {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
}

.icon-wrapper_close {
  left: 97.5%;
  top: 2%;

  transform: translate(-100%, 0);
}

.icon {
  width: calc(var(--text-size-lrg) * 3);
  height: calc(var(--text-size-lrg) * 3);

  color: #ddd;

  transition: all 0.3s;
}

.common-overlay__text {
  font-size: calc(var(--text-size-input-sml) * 1.15);
  letter-spacing: 1.4px;
  font-weight: 600;
  color: #eee;
}

.common-overlay__text__sml {
  font-size: var(--text-size-input-sml);
  letter-spacing: 1.4px;
  font-weight: 600;
  color: #eee;
}

.common-icon__wrapper {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
}

.common-icon {
  width: calc(var(--text-size-input-lrg) * 1.25);
  height: calc(var(--text-size-input-lrg) * 1.25);
  color: #eee;

  transition: all 0.3s;

  pointer-events: none;
}

.common-icon__sml {
  width: var(--text-size-input-lrg);
  height: var(--text-size-input-lrg);
  color: #eee;

  transition: all 0.3s;

  pointer-events: none;
}

@media (hover: hover) {
  .icon-wrapper:hover .icon {
    color: #fff;
  }

  .common-icon__wrapper:hover .common-icon {
    color: #fff;
    transform: scale(1.15);
  }

  .common-icon__wrapper:hover .common-icon__sml {
    color: #fff;
    transform: scale(1.15);
  }
}
