.toolbar__container {
  width: 100%;

  display: flex;
  justify-content: flex-end;
  gap: var(--gap-med);

  padding: var(--pad-sml) var(--pad-med);

  border-bottom: 2px solid #333;

  list-style: none;
}

.icon__wrapper {
  position: relative;

  display: flex;
  align-items: center;
  gap: var(--gap-sml);

  padding: calc(var(--pad-sml) * 0.2) calc(var(--pad-sml) * 0.8);

  border: none;
  border-radius: 6px;

  cursor: pointer;

  border: 2px solid #333;

  transition: all 0.3s;
}

.icon {
  color: #333;
  width: var(--text-size-input-lrg);
  height: var(--text-size-input-lrg);

  pointer-events: none;

  transition: all 0.3s;
}

.icon__wrapper__arrange {
  border: 2px solid var(--color-blue);
}

.icon__wrapper__arrange .icon {
  color: var(--color-blue);
}

.icon__wrapper__arrange_active {
  background-color: var(--color-blue);
}

.icon__wrapper__arrange_active .icon {
  color: #eee;
}

.icon__wrapper__add {
  border: 2px solid var(--color-green);
}

.icon__wrapper__add .icon {
  color: var(--color-green);
}

.icon__wrapper__select {
}

.icon__wrapper__delete {
  border: 2px solid var(--color-red);
}

.icon__wrapper__delete .icon {
  color: var(--color-red-shade);
}

.icon__wrapper__save {
  border: 2px solid var(--color-blue);
}

.icon__wrapper__save .icon {
  color: var(--color-blue);
}

.icon__wrapper__select_active {
  background-color: #333;
}

.icon__wrapper__select_active .icon {
  color: #eee;
}

.icon__wrapper__disabled {
  border: 2px solid #888;
  opacity: 0.75;

  cursor: not-allowed;
}

.icon__wrapper__disabled .icon {
  color: #888;
  opacity: 0.75;
}

.tooltip {
  visibility: hidden;
}

@media (hover: hover) {
  .icon__wrapper:hover {
    box-shadow: 0 0 3px 1px #33333375;
  }

  .icon__wrapper:hover .icon {
    transform: scale(1.15);
  }

  .icon__wrapper__arrange:hover {
    box-shadow: 0 0 3px 1px var(--color-blue-shadow);
  }

  .icon__wrapper__add:hover {
    box-shadow: 0 0 3px 1px var(--color-green-shadow);
  }

  .icon__wrapper__delete:hover {
    box-shadow: 0 0 3px 1px var(--color-red-shade-shadow);
  }

  .icon__wrapper__save:hover {
    box-shadow: 0 0 3px 1px var(--color-blue-shadow);
  }

  .icon__wrapper__disabled:hover .icon {
    transform: scale(1);
  }

  .icon__wrapper:hover .tooltip {
    visibility: visible;
  }
}
