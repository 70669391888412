.edit__modal__wrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--pad-modal);
}

.title__container {
  background-color: #ae3ec9;

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}
