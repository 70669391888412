.toolbar__container {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 0 auto;
  margin-top: var(--margin-sml);

  border-radius: 20px;
}

.icons__container {
  display: flex;

  align-items: center;
  justify-content: center;

  gap: var(--gap-lrg);
  padding: calc(var(--pad-sml) * 0.5) var(--pad-med);
  border-radius: 6px;

  background-color: var(--color-main-tint);
  background-color: #eee;

  box-shadow: 0 0 4px 2px rgba(0, 0, 0, 0.5);
}

.icon__wrapper {
  position: relative;

  cursor: pointer;
}

.icon {
  color: var(--color-blue);

  width: calc(var(--text-size-input-med) * 1.5);
  height: calc(var(--text-size-input-med) * 1.5);

  opacity: 0.8;

  transition: all 0.3s;
}

.tooltip {
  visibility: hidden;
}

@media (hover: hover) {
  .icon__wrapper:hover .icon {
    color: var(--color-purple-highlight);

    opacity: 1;

    transform: scale(1.25);
  }

  .icon__wrapper:hover .tooltip {
    visibility: visible;
  }
}

@media (hover: none) {
  .icon {
    opacity: 1;
  }
}
