/* 
  INDEX
    - MODAL BACKDROP (Edit Image, Edit Desc): 5
    - MODAL OVERLAY: 6
    - IMAGE MODAL: 6

    - IMAGE UPLOAD MODAL: 7
    - CONFIRMATION MODAL: 10

    - LOADING MODAL: 19

    - ERROR MODAL BACKDROP: 20
    - ERROR MODAL OVERLAY: 21
*/

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --color-bg: #fff7f7;
  /* --color-bg: #fff; */
  --color-main: #f783ac;
  --color-main-tint: #faa2c1;
  --color-main-shade: #e64980;
  --color-text-black: #333;
  --color-text-gray: #999;

  --color-green: #2f9e44;
  --color-green-shadow: #2f9e4373;
  --color-green-highlight: #40c057;
  --color-purple: #9c36b5;
  --color-purple-shadow: #9c36b57c;
  --color-purple-highlight: #be4bdb;
  --color-purple-tint: #cc5de8;
  --color-purple-tint-shadow: #cc5de87e;
  --color-purple-tint-highlight: #e599f7;
  --color-yellow: #e67700;
  --color-yellow-shadow: #f08c008a;
  --color-yellow-highlight: #f59f00;
  --color-red: #f03e3e;
  --color-red-shadow: #f03e3e88;
  --color-red-highlight: #ff6b6b;
  --color-red-shade: #e03131;
  --color-red-shade-shadow: #e0313183;
  --color-red-shade-highlight: #fa5252;
  --color-blue: #1c7ed6;
  --color-blue-shadow: #1970c281;
  --color-blue-highlight: #339af0;
  --color-blue-tint: #4dabf7;
  --color-blue-tint-shadow: #4dabf77c;
  --color-blue-tint-highlight: #a5d8ff;

  --text-size-base: 1rem;
  --text-size-sml: calc(var(--text-size-base) * 1.4);
  --text-size-med: calc(var(--text-size-base) * 1.8);
  --text-size-lrg: calc(var(--text-size-base) * 2.4);

  --text-size-input-base: 1rem;
  --text-size-input-sml: calc(var(--text-size-input-base) * 1.6);
  --text-size-input-med: calc(var(--text-size-input-base) * 1.8);
  --text-size-input-lrg: calc(var(--text-size-input-base) * 2.4);

  --text-font-cursive: Brush Script MT, Brush Script Std, cursive;
  --text-font-consolas: Consolas, monaco, monospace;

  --gap-base: 1rem;
  --gap-sml: calc(var(--gap-base) * 1.2);
  --gap-med: calc(var(--gap-base) * 1.6);
  --gap-lrg: calc(var(--gap-base) * 2);

  --margin-base: 1rem;
  --margin-sml: calc(var(--margin-base) * 2.4);
  --margin-med: calc(var(--margin-base) * 3.6);
  --margin-lrg: calc(var(--margin-base) * 4.8);

  --pad-base: 1rem;
  --pad-sml: calc(var(--pad-base) * 1.6);
  --pad-med: calc(var(--pad-base) * 2.4);
  --pad-lrg: calc(var(--pad-base) * 3.2);

  --pad-modal: calc(var(--pad-sml) * 0.75) var(--pad-sml);
  --pad-modal-sml: calc(var(--pad-sml) * 0.3) var(--pad-sml);

  --size-base: 1rem;
  --size-sml: calc(var(--size-base) * 10);
  --size-med: calc(var(--size-base) * 25);
  --size-lrg: calc(var(--size-base) * 50);
}

input[type='file'] {
  display: none;
}

input[type='text']:not(.overwrite-style),
input[type='password']:not(.overwrite-style) {
  width: 100%;
  min-width: 0;

  font-size: var(--text-size-input-sml);
  color: var(--color-text-black);
  letter-spacing: 1.05px;

  padding: calc(var(--pad-sml) * 0.5) calc(var(--pad-sml) * 0.5);

  border: 1px solid #3333339f;
  border-radius: 4px;

  transition: all 0.3s;
}

@media (hover: hover) {
  input[type='text']:hover:not(.overwrite-style),
  input[type='password']:hover:not(.overwrite-style) {
    outline: none;
    box-shadow: 0px 0px 5px 4px var(--color-blue-shadow);
  }
}

input[type='text']:focus:not(.overwrite-style),
input[type='password']:focus:not(.overwrite-style) {
  outline: none;
  box-shadow: 0px 0px 5px 4px var(--color-blue-shadow);
}

input[type='text']::placeholder:not(.overwrite-style),
input[type='password']::placeholder:not(.overwrite-style) {
  font-size: var(--text-size-input-sml);
  letter-spacing: 1.1px;
  color: #33333383;
}

input[type='text']:focus:not(.overwrite-style),
input[type='password']:focus:not(.overwrite-style) {
  outline: none;
}

label:not(.overwrite-style),
.label:not(.overwrite-style) {
  font-size: calc(var(--text-size-input-sml) * 0.9);
  font-weight: 600;
  letter-spacing: 1.2px;
  color: #333;
  opacity: 0.85;
}

button {
  font-size: var(--text-size-input-sml);
}

@media (max-width: 1536px) {
  :root {
    --text-size-base: 0.9rem;

    --gap-base: 0.9rem;

    --margin-base: 0.9rem;

    --pad-base: 0.94rem;

    --size-base: 0.9rem;

    --text-size-input-base: 0.96rem;
  }
}

@media (max-width: 1280px) {
  :root {
    --text-size-base: 0.85rem;

    --gap-base: 0.85rem;

    --margin-base: 0.85rem;

    --pad-base: 0.9rem;

    --size-base: 0.85rem;

    --text-size-input-base: 0.92rem;
  }
}

@media (max-width: 1024px) {
  :root {
    --text-size-base: 0.8rem;

    --gap-base: 0.8rem;

    --margin-base: 0.8rem;

    --pad-base: 0.86rem;

    --size-base: 0.8rem;

    --text-size-input-base: 0.88rem;
  }
}

@media (max-width: 768px) {
  :root {
    --text-size-base: 0.76rem;

    --gap-base: 0.76rem;

    --margin-base: 0.76rem;

    --pad-base: 0.82rem;

    --size-base: 0.76rem;

    --text-size-input-base: 0.86rem;
  }
}

@media (max-width: 640px) {
  :root {
    --text-size-base: 0.72rem;

    --gap-base: 0.72rem;

    --margin-base: 0.72rem;

    --pad-base: 0.78rem;

    --size-base: 0.72rem;

    --text-size-input-base: 0.84rem;
  }

  input[type='text']:not(.overwrite-style),
  input[type='password']:not(.overwrite-style) {
    font-size: 16px;
  }
  input[type='text']::placeholder:not(.overwrite-style),
  input[type='password']::placeholder:not(.overwrite-style) {
    font-size: 16px;
  }
  label:not(.overwrite-style),
  .label:not(.overwrite-style) {
    font-size: calc(16px * 0.9);
  }
}

@media (max-width: 475px) {
  :root {
    --text-size-base: 0.64rem;

    --gap-base: 0.64rem;

    --margin-base: 0.64rem;

    --pad-base: 0.74rem;

    --size-base: 0.64rem;

    --text-size-input-base: 0.82rem;
  }
}

@media (max-width: 340px) {
  :root {
    --text-size-base: 0.58rem;

    --gap-base: 0.58rem;

    --margin-base: 0.58rem;

    --pad-base: 0.7rem;

    --size-base: 0.58rem;

    --text-size-input-base: 0.72rem;
  }
}

@media (max-width: 280px) {
  :root {
    --text-size-input-base: 0.54rem;
  }
}

:root::-webkit-scrollbar {
  width: 6px;
}

:root::-webkit-scrollbar-track {
  background-color: #333;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

:root::-webkit-scrollbar-thumb {
  background-color: #eee;
  border-radius: 100vw;
}

@media (hover: hover) {
  :root::-webkit-scrollbar-thumb:hover {
    background-color: #fff;
  }
}

:root::-webkit-scrollbar-track {
  background-color: #999;
  border-radius: 100vw;
  margin-block: 0.5rem;
}

:root::-webkit-scrollbar-thumb {
  background-color: #333;
  border-radius: 100vw;
}

@media (hover: hover) {
  :root::-webkit-scrollbar-thumb:hover {
    background-color: #222;
  }
}
