.bg {
  position: fixed;
  top: 0;
  left: 0;

  z-index: -999;

  background-color: var(--color-bg);

  width: 100vw;
  height: 100vh;
}

.app {
  width: 100%;
  /* height: 100vh; */

  overflow: auto;
}
