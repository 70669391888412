.img__container {
  width: 0;

  position: absolute;
  top: 0;
  left: 0;

  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.25);

  display: none;

  cursor: pointer;

  transition: all 0.3s;
}

.img__container img {
  width: 100%;
  height: 100%;

  transition: all 0.3s;
}

@media (hover: hover) {
  .img__container:hover img {
    filter: brightness(0.4);
  }
}
