.form__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--pad-sml);

  padding: var(--pad-modal);
}

.input__container {
  display: flex;
  flex-direction: column;
  gap: calc(var(--pad-sml) * 0.5);
}

.input__wrapper {
  position: relative;

  width: 100%;

  --pad-input-info: calc(var(--pad-sml) * 0.5);
  --icon-size: calc(var(--text-size-input-med) * 1.15);
}

.input__wrapper input {
  padding-right: calc(
    var(--pad-input-info) + calc(var(--icon-size) * 1.5)
  ) !important;
}

.icon__wrapper {
  position: absolute;
  right: 0;
  bottom: 50%;

  transform: translate(-50%, 50%);

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: var(--icon-size);
  height: var(--icon-size);

  color: rgba(0, 0, 0, 0.75);
}

.tooltip {
  position: absolute;

  width: 85%;

  right: 0;
  top: 100%;

  transform: translate(0, 10%);

  background-color: #333;
  padding: calc(var(--pad-sml) * 0.3) var(--pad-sml);
  border-radius: 5px;
  box-shadow: 0px 0px 3px 1px #fff;

  font-size: var(--text-size-sml);
  letter-spacing: 1.05px;
  line-height: calc(var(--text-size-med) * 1.25);
  color: #eee;

  pointer-events: none;

  visibility: hidden;

  z-index: 8;
}

.tooltip::after {
  content: '';
  position: absolute;
  bottom: 100%;
  right: 0;

  transform: translateX(-150%);

  border-width: calc(var(--text-size-sml) * 0.4);
  border-style: solid;
  border-color: transparent transparent black transparent;
}

@media (hover: hover) {
  .icon__wrapper:hover + .tooltip {
    visibility: visible;
  }
}
