.form__container {
  width: 95vw;
  max-width: 35rem;

  margin: 0 auto;
  margin-top: var(--margin-med);
  padding: var(--pad-lrg) var(--pad-lrg);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: calc(var(--gap-lrg) * 1.25);

  background-image: linear-gradient(#c2255c, #9c36b5);
  border-radius: 14px;

  transition: all 0.3s;

  --color-hover: #74c0fc;
}

.header__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: var(--gap-med);
}

.header_icon__container {
  padding: calc(var(--pad-sml) * 0.9);

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
}

.icon {
  color: #333;

  width: calc(var(--text-size-lrg) * 1.1);
  height: calc(var(--text-size-lrg) * 1.1);

  transition: all 0.3s;
}

.header__container p {
  color: #fff;

  font-size: calc(var(--text-size-input-med) * 1.1);
  font-weight: 600;
  letter-spacing: 1.2px;
}

.input__container {
  width: 100%;

  display: flex;
  align-items: center;
  gap: var(--gap-med);

  padding: calc(var(--pad-sml) * 0.25);
  padding-bottom: calc(var(--pad-sml) * 0.5);

  margin-bottom: calc(var(--margin-sml) * 0.25);

  border-bottom: 1.2px solid rgba(250, 250, 250, 0.8);

  transition: all 0.3s;
}

.input__icon {
  width: calc(var(--text-size-lrg));
  height: calc(var(--text-size-lrg));

  color: #eee;
}

.input__container:focus-within {
  border-color: var(--color-hover);
}

.input__container:focus-within .input__icon {
  color: var(--color-hover);

  transform: scale(1.2);
}

.input__container input {
  flex: 1;
  min-width: 0;

  background-color: transparent;
  border: none;
  padding-top: calc(var(--text-size-sml) * 0.25);

  font-size: calc(var(--text-size-input-sml) * 0.9);
  color: #eee;
  letter-spacing: 1.1px;
}

.input__container input::placeholder {
  font-size: calc(var(--text-size-input-sml) * 0.8);
  color: #eee;
  letter-spacing: 1.1px;
  opacity: 0.65;
}

.input__container input:focus {
  outline: none;
}

.icon__wrapper {
  cursor: pointer;
}

.pw__icon {
  width: calc(var(--text-size-lrg));
  height: calc(var(--text-size-lrg));

  color: #eee;

  pointer-events: none;
}

.submit__btn {
  background-color: #eee;

  padding: calc(var(--pad-sml) * 0.5) var(--pad-med);
  margin-top: calc(var(--margin-sml) * 0.25);

  border: none;
  border-radius: 16px;

  font-weight: 600;
  color: #333;

  cursor: pointer;
  transition: all 0.3s;
}

.submit__btn_disabled {
  background-color: #999999a2;
  color: #555;

  cursor: not-allowed;
}

.error__text {
  margin-bottom: calc(var(--margin-sml) * 0.5);

  color: #ffec99;
  font-size: var(--text-size-sml);
  font-weight: 600;
}

@media (hover: hover) {
  .submit__btn:hover {
    background-color: #fff;
  }
}

@media (max-width: 768px) {
  .input__container input {
    font-size: calc(var(--text-size-input-sml) * 1.15);
  }

  .input__container input::placeholder {
    font-size: var(--text-size-input-sml);
  }
}

@media (max-width: 640px) {
  .input__icon {
    width: calc(var(--text-size-lrg));
    height: calc(var(--text-size-lrg));
  }
  .input__container input {
    font-size: calc(16px * 1);
  }

  .input__container input::placeholder {
    font-size: calc(16px * 0.9);
  }
}
