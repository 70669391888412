.main__container {
  max-width: 100rem;

  margin: var(--margin-sml) auto;

  /* background-color: rgba(0, 0, 255, 0.295); */
}

.about__container {
  width: 100%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: calc(var(--gap-lrg) * 2);

  padding: var(--pad-lrg);
}

.background__containers {
  background-color: #fff;
  background-color: #fffcfc;
  box-shadow: 0 0 6px 1px rgba(0, 0, 0, 0.1);
  padding: var(--pad-med);
  border-radius: 6px;
}

.left__container {
  flex: 2;

  display: flex;
  flex-direction: column;
  gap: var(--gap-lrg);
  align-items: center;
}

.img__container {
  border-radius: 50%;

  width: 100%;
}

.img__wrapper {
  position: relative;

  width: 100%;

  aspect-ratio: 1/1;

  border-radius: 6px;
}

.img__wrapper img {
  width: 100%;
  height: auto;

  border-radius: 50%;
}

.name__container {
  width: 100%;
  padding: 0 var(--pad-med);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  gap: calc(var(--gap-sml) * 0.4);

  margin-bottom: var(--margin-sml);
}

.name__container p {
  font-size: calc(var(--text-size-input-med) * 1.25);
  font-weight: 600;
  letter-spacing: 1.05px;
  color: #333;
}

.name__container P:not(:first-child) {
  font-size: calc(var(--text-size-input-sml) * 0.75);
  font-weight: 500;
  color: #888;
  opacity: 0.9;
}

.tabs__container {
  list-style: none;

  width: 100%;
  padding: 0 var(--pad-med);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-lrg);
}

.tab {
  font-size: var(--text-size-med);
  font-weight: 600;
  color: #aaa;
  letter-spacing: 1.05px;

  border: 2px solid #333;
  border-color: transparent;

  cursor: pointer;
  transition: all 0.3s;
}

.tab__active {
  color: var(--color-main-shade);

  border-bottom-color: var(--color-main-shade);
}

.right__container {
  flex: 5;
}

.photo_icon__container {
  position: absolute;
  bottom: 0;
  right: 0;

  transform: translate(-25%, -25%);

  background-color: #eee;
  border-radius: 50%;

  padding: calc(var(--pad-sml) * 0.75);
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.35);

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  transition: all 0.3s;
}

.icon {
  width: var(--text-size-input-lrg);
  height: var(--text-size-input-lrg);
  color: var(--color-blue);

  pointer-events: none;
  transition: all 0.3s;
}

@media (hover: hover) {
  .tab:hover {
    color: #bbb;
  }
  .tab__active:hover {
    color: var(--color-main);
  }

  .photo_icon__container:hover {
    background-color: #fff;
  }

  .photo_icon__container:hover .icon {
    color: var(--color-blue-highlight);
  }
}

@media (max-width: 768px) {
  .about__container {
    flex-direction: column;

    padding: var(--pad-med);
  }

  .left__container {
    flex-direction: row;
    flex-wrap: wrap;
  }

  .img__container {
    width: 25%;
  }

  .name__container {
    width: 70%;
  }

  .tabs__container {
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;

    margin-top: var(--margin-sml);
  }

  .right__container {
    width: 100%;
  }
}

@media (max-width: 475px) {
  .about__container {
    padding: var(--pad-sml);
  }

  .img__container {
    width: 100%;

    justify-self: center;
    align-self: center;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img__wrapper {
    width: 50%;
  }

  .name__container {
    width: 100%;

    align-items: center;
    justify-content: center;
  }
}
