.main__container {
  width: 100%;

  margin-top: var(--margin-med);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: calc(var(--gap-lrg) * 2);

  padding: 0 var(--pad-lrg);
}

.content_description__container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-description {
  color: #333;
  font-size: calc(var(--text-size-lrg) * 1.25);
  font-family: var(--text-font-cursive);
  letter-spacing: 1.3px;
  opacity: 0.8;
}

.loading__container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--pad-lrg) var(--pad-med);
}

.spinner {
  width: calc(var(--text-size-lrg) * 1.75);
  height: calc(var(--text-size-lrg) * 1.75);

  border-radius: 50%;
  border: 2px solid var(--color-blue);
  border-top-color: transparent;
  border-bottom-color: transparent;

  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1024px) {
  .main__container {
    padding: 0 var(--pad-med);
  }
}

@media (max-width: 768px) {
  .main__container {
    padding: 0 var(--pad-sml);
  }
}
