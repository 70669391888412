.overlay__container {
  position: fixed;
  inset: 0;

  width: 100vw;
  height: 100vh;

  background-color: transparent;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding-top: var(--pad-med);

  z-index: 6;
}

.edit__container {
  width: 95vw;
  max-width: 40rem;
  max-height: 95vh;

  background-color: #eee;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.btns__container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: var(--gap-med);

  padding: var(--pad-modal);
}

.btn {
  font-weight: 600;
  color: #333;

  padding: calc(var(--pad-sml) * 0.25) calc(var(--pad-sml) * 0.8);
  border: 2px solid #333;
  border-radius: 4px;

  background-color: #eee;

  cursor: pointer;
  transition: all 0.3s;
}

.btn-confirm {
  color: #eee;

  border-color: var(--color-green);
  background-color: var(--color-green);
}

@media (hover: hover) {
  .btn-confirm:hover {
    background-color: var(--color-green-highlight);
  }

  .btn-cancel:hover {
    background-color: #fff;
  }

  .btn__disabled,
  .btn__disabled:hover {
    background-color: #aaa;
    border-color: #aaa;
    color: #fff;

    cursor: not-allowed;
  }
}
