.img-list-container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--gap-sml);

  padding: var(--pad-sml) var(--pad-med);

  overflow-y: auto;
  overflow-x: hidden;
}

.img-item__container {
  width: 100%;

  display: flex;

  align-items: center;

  border: 2px solid #333;
  border-radius: 6px;

  background-color: #ffdeeb;
  background-color: #333;

  box-shadow: 2px 2px 6px 3px rgba(0, 0, 0, 0.25);

  padding: calc(var(--pad-sml) * 0.5) 0;

  transition: all 0.15s;
}

.selectable {
  cursor: pointer;
}

.img-item__container_selected {
  box-shadow: 0 0 4px 2px var(--color-blue);
}

.img__container {
  flex: 11;

  height: calc(var(--size-sml) * 1.15);

  display: flex;
  align-items: center;
  justify-content: flex-start;

  /* background-color: #0000006b; */
  /* background-color: #222; */
  background-color: #dfdfdf;
  border-radius: 4px;
  overflow: hidden;

  padding: calc(var(--pad-sml) * 0.5) var(--pad-sml);
}

.img__container img {
  max-width: 100%;
  max-height: 100%;

  border-radius: 4px;
}

.tab__container {
  height: calc(var(--size-sml) * 0.75);
  background-color: var(--color-bg);
}

.tab__container p {
  font-size: var(--text-size-med);
  font-weight: 700;
  color: #333;
  color: var(--color-main-shade);
  letter-spacing: 1.1px;
}

.index__container {
  flex: 1;

  display: flex;

  align-items: center;
  justify-content: center;
}

.index__container p {
  font-size: var(--text-size-lrg);
  font-family: var(--text-font-consolas);
  font-weight: 600;
  color: #eee;
}

.edit__container {
  flex: 1.5;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon__wrapper {
  cursor: pointer;

  width: 100%;
  aspect-ratio: 1/1;

  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 65%;
  height: 65%;

  transition: all 0.3s;

  pointer-events: none;
}

.icon__edit {
  color: var(--color-yellow);
}

.icon__delete {
  color: var(--color-red);
}

.icon__arrange {
  color: var(--color-blue-tint);
}

.icon__select {
  width: calc(var(--text-size-med) * 0.9);
  height: calc(var(--text-size-med) * 0.9);

  background-color: #ddd;

  border-radius: 50%;
}

.icon__select_active {
  background-color: var(--color-blue-tint);

  box-shadow: 0 0 2px 1px var(--color-blue-tint);
}

@media (hover: hover) {
  .img-item__container:hover {
    box-shadow: 0 0 4px 2px var(--color-purple);
  }

  .img-item__container_selected:hover {
    box-shadow: 0 0 6px 3px var(--color-purple);
  }

  .icon__wrapper:hover .icon {
    transform: scale(1.15);
  }

  .icon__wrapper:hover .icon__edit {
    color: var(--color-yellow-highlight);
  }

  .icon__wrapper:hover .icon__delete {
    color: var(--color-red-highlight);
  }
  .icon__wrapper:hover .icon__arrange {
    color: var(--color-blue-tint-highlight);
  }

  .icon__wrapper:hover .icon__select {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .img__container {
    height: calc(var(--size-sml) * 1.5);
  }
}
