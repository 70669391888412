.form__container {
  width: 100%;

  flex: 1;

  display: flex;
  flex-direction: column;
  gap: var(--gap-lrg);

  padding-top: var(--pad-sml);

  /* overflow-y: auto; */
}

.input__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--gap-sml);

  padding: var(--pad-modal);
  padding-top: 0;
  padding-bottom: 0;
}

.editor__container {
  width: 100%;

  flex: 1;

  padding: 0;

  /* overflow-y: auto; */
}

.editor__container label {
  padding: var(--pad-modal);
  padding-top: 0;
  padding-bottom: 0;
}

@media (max-height: 500px) {
  .form__container {
    overflow-y: auto;
  }
}
