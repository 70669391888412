.cropper__container {
  position: relative;
}

.slider__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--gap-sml);
}

.slider_label__container {
  display: flex;
  align-items: center;
  gap: var(--gap-sml);
}

.slider_label__container label:not(:first-child) {
  transform: scale(1.25);
  color: var(--color-blue);
}

/* .slider__container input {
  width: 100%;

  -webkit-appearance: none;
  appearance: none;

  width: 100%;
  height: calc(var(--text-size-input-sml) * 1);
  opacity: 0.7;

  background: #333;
  border-radius: 25px;
  outline: none;

  box-shadow: inset 0 0 5px rgba(0, 0, 0, 1);

  overflow: hidden;

  transition: all 0.3s;
}

.slider__container input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;

  width: calc(var(--text-size-input-sml) * 1);
  height: calc(var(--text-size-input-sml) * 1);

  background: #faa2c1;
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.25),
    -400px 0 0 400px rgba(255, 255, 255, 0.75);

  cursor: pointer;

  transition: all 0.3s;
}

.slider__container input::-moz-range-thumb {
  width: calc(var(--text-size-input-med) * 1.15);
  height: calc(var(--text-size-input-med) * 1.15);

  background: var(--color-blue);
  border-radius: 50%;
  box-shadow: 0 0 1px 1px rgba(255, 255, 255, 0.25);

  cursor: pointer;

  transition: all 0.3s;
}

@media (hover: hover) {
  .slider__container input:hover {
    background: #444;
  }

  .slider__container input::-webkit-slider-thumb:hover {
    background: #fcc2d7;
  }

  .slider__container input::-moz-range-thumb:hover {
    background: var(--color-blue-highlight);
  }
} */
