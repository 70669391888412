.form__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: calc(var(--gap-lrg) * 1);

  padding: var(--pad-modal);

  overflow-y: auto;
}

.img-preview__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--gap-med);
}

.preview__label {
}

.img__wrapper {
  height: 40vh;
  max-height: 64rem;

  background-color: #4444448e;

  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.desc__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: var(--gap-sml);

  padding: var(--pad-sml) 0;
}

.img__wrapper img {
  max-width: 100%;
  max-height: 100%;
}

.btns__container {
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding-bottom: var(--pad-sml);
}

.btns__container_update {
  justify-content: flex-end;
}

.action-btns__container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: var(--gap-sml);
}

.btn {
  background-color: transparent;

  padding: calc(var(--pad-sml) * 0.5) calc(var(--pad-sml));

  border: none;
  border-radius: 4px;

  font-weight: 600;

  cursor: pointer;

  transition: all 0.3s;
}

.btn-upload {
  background-color: var(--color-green);

  color: #eee;
}

.btn-upload__disabled {
  background-color: #aaa;
  color: #fff;

  cursor: not-allowed;
}

.btn-cancel {
  box-shadow: inset 0 0 1px 1.2px #333;
}

.btn-choose {
  position: relative;

  background-color: var(--color-yellow);

  color: #eee;
}

.upload-img__label {
  position: absolute;

  inset: 0;

  width: 100%;
  height: 100%;

  cursor: pointer;
}

@media (hover: hover) {
  .btn-upload:hover {
    background-color: var(--color-green-highlight);
  }

  .btn-upload__disabled:hover {
    background-color: #aaa;
  }

  .btn-cancel:hover {
    /* box-shadow: inset 0 0 1px 1.2px #333, 0 0 3px 1px #333; */

    background-color: #fff;
  }

  .btn-choose:hover {
    background-color: var(--color-yellow-highlight);
  }
}
