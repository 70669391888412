.container {
  width: 100%;
  display: flex;

  align-items: center;
  justify-content: center;
  gap: var(--gap-lrg);

  margin-top: var(--margin-lrg);
  padding: var(--pad-lrg) var(--pad-lrg);
}

.icon-wrapper {
  cursor: pointer;
}

.footer-icon {
  color: var(--color-text-black);

  width: calc(var(--text-size-lrg) * 1.5);
  height: calc(var(--text-size-lrg) * 1.5);

  pointer-events: none;
  transition: all 0.3s;
}

@media (hover: hover) {
  .icon-wrapper:hover .footer-icon {
    color: var(--color-main);
  }
}

@media (hover: none) {
  .footer-icon {
    color: var(--color-main);
  }
}
