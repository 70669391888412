.main__container {
  position: fixed;
  inset: 0;
  left: 50%;

  transform: translate(-50%, 100%);

  max-width: 24rem;
  width: 95vw;
  height: 3.8rem;

  border-radius: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: var(--color-yellow);
  background-color: #eee;

  box-shadow: 0 0 2px 2px var(--color-main), 0 0 10px 6px var(--color-main);

  padding: 0 var(--pad-med);

  animation: breath 2s ease-in-out infinite;

  z-index: 19;
}

.header__text {
  color: var(--color-yellow) !important;
}

@keyframes breath {
  0% {
    box-shadow: 0 0 2px 2px var(--color-yellow), 0 0 0 var(--color-yellow);
  }

  50% {
    box-shadow: 0 0 2px 2px var(--color-yellow),
      0 0 10px 6px var(--color-yellow);
  }

  100% {
    box-shadow: 0 0 2px 2px var(--color-yellow), 0 0 0 var(--color-yellow);
  }
}

.slide-in {
  animation: slideIn 0.3s ease-out forwards;
}

.slide-out {
  animation: slideOut 0.3s ease-out forwards;
}

@keyframes slideIn {
  from {
    transform: translate(-50%, -200%);
  }

  to {
    transform: translate(-50%, 100%);
  }
}

@keyframes slideOut {
  from {
    transform: translate(-50%, 100%);
  }

  to {
    transform: translate(-50%, -200%);
  }
}
