.editor__container {
  width: 100%;

  flex: 1;

  display: flex;
  flex-direction: column;
  gap: var(--gap-sml);

  padding: 0;
}

.label__container {
  display: flex;

  align-items: center;
  gap: var(--gap-sml);
}

.triangle {
  --triangle-width: calc(var(--text-size-input-sml) * 0.35);
  --triangle-height: calc(var(--triangle-width) * 1.732);

  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: 0 var(--triangle-width) var(--triangle-height)
    var(--triangle-width);
  border-color: transparent transparent #333 transparent;
  transform: rotate(180deg);

  cursor: pointer;

  transition: all 0.4s;
}

.triangle-show {
  transform: rotate(0);
}

.editor__wrapper {
  height: 0;

  overflow: hidden;
}
