.html__container {
  line-height: 3.6rem;

  --h2-mul: 1.75;
  --h3-mul: 1.5;
  --h4-mul: 1.25;

  --tiny-mul: 0.75;
  --small-mul: 0.85;
  --big-mul: 1.15;
  --huge-mul: 1.35;

  --text-base: calc(var(--text-size-input-sml) * 1.25);
}

.html__container * {
  font-size: var(--text-base);
  /* color: #333; */
}

.html__container a:link,
.html__container a:visited {
  color: #1971c2;
  text-decoration: none;

  transition: all 0.3s;
}

.html__container a:hover,
.html__container a:active {
  color: #339af0;
}

.html__container a:link *,
.html__container a:visited * {
  color: #1971c2;
  text-decoration: none;

  transition: all 0.3s;
}

.html__container a:hover *,
.html__container a:active * {
  color: #339af0;
}

.html__container ul,
.html__container ol {
  margin-left: var(--margin-sml);
}

/* .html__container p {
  font-size: var(--text-base);
} */

.html__container .text-tiny,
.html__container .text-tiny strong,
.html__container .text-tiny i {
  font-size: calc(var(--text-base) * var(-tiny-mul));
}

.html__container .text-small,
.html__container .text-small strong,
.html__container .text-small i {
  font-size: calc(var(--text-base) * var(--small-mul));
}

.html__container .text-big,
.html__container .text-big strong,
.html__container .text-big i {
  font-size: calc(var(--text-base) * var(--big-mul));
}

.html__container .text-huge,
.html__container .text-huge strong,
.html__container .text-huge i {
  font-size: calc(var(--text-base) * var(--huge-mul));
}

.html__container h2,
.html__container h2 strong,
.html__container h2 i {
  font-size: calc(var(--text-base) * var(--h2-mul));
}

.html__container h2 .text-tiny,
.html__container h2 .text-tiny strong,
.html__container h2 .text-tiny i {
  font-size: calc(var(--text-base) * var(--h2-mul) * var(-tiny-mul));
}

.html__container h2 .text-small,
.html__container h2 .text-small strong,
.html__container h2 .text-small i {
  font-size: calc(var(--text-base) * var(--h2-mul) * var(--small-mul));
}

.html__container h2 .text-big,
.html__container h2 .text-big strong,
.html__container h2 .text-big i {
  font-size: calc(var(--text-base) * var(--h2-mul) * var(--big-mul));
}

.html__container h2 .text-huge,
.html__container h2 .text-huge strong,
.html__container h2 .text-huge i {
  font-size: calc(var(--text-base) * var(--h2-mul) * var(--huge-mul));
}

.html__container h3,
.html__container h3 strong,
.html__container h3 i {
  font-size: calc(var(--text-base) * var(--h3-mul));
}

.html__container h3 .text-tiny,
.html__container h3 .text-tiny strong,
.html__container h3 .text-tiny i {
  font-size: calc(var(--text-base) * var(--h3-mul) * var(-tiny-mul));
}

.html__container h3 .text-small,
.html__container h3 .text-small strong,
.html__container h3 .text-small i {
  font-size: calc(var(--text-base) * var(--h3-mul) * var(--small-mul));
}

.html__container h3 .text-big,
.html__container h3 .text-big strong,
.html__container h3 .text-big i {
  font-size: calc(var(--text-base) * var(--h3-mul) * var(--big-mul));
}

.html__container h3 .text-huge,
.html__container h3 .text-huge strong,
.html__container h3 .text-huge i {
  font-size: calc(var(--text-base) * var(--h3-mul) * var(--huge-mul));
}

.html__container h4,
.html__container h4 strong,
.html__container h4 i {
  font-size: calc(var(--text-base) * var(--h4-mul));
}

.html__container h4 .text-tiny,
.html__container h4 .text-tiny strong,
.html__container h4 .text-tiny i {
  font-size: calc(var(--text-base) * var(--h4-mul) * var(-tiny-mul));
}

.html__container h4 .text-small,
.html__container h4 .text-small strong,
.html__container h4 .text-small i {
  font-size: calc(var(--text-base) * var(--h4-mul) * var(--small-mul));
}

.html__container h4 .text-big,
.html__container h4 .text-big strong,
.html__container h4 .text-big i {
  font-size: calc(var(--text-base) * var(--h4-mul) * var(--big-mul));
}

.html__container h4 .text-huge,
.html__container h4 .text-huge strong,
.html__container h4 .text-huge i {
  font-size: calc(var(--text-base) * var(--h4-mul) * var(--huge-mul));
}
