.container {
  width: 100%;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  gap: calc(var(--gap-sml) / 2);

  margin-top: 4.2rem;

  padding: 0 2.4rem;
}

.name:link,
.name:visited,
.name:active {
  text-decoration: none;
}

.name {
  font-size: calc(var(--text-size-lrg) * 2.25);
  font-weight: 700;
  color: var(--color-main);
  letter-spacing: 1.6px;
}

.title {
  font-size: calc(var(--text-size-sml));
  /* color: var(--color-main-tint); */
  color: var(--color-main);
  letter-spacing: 1.8px;
  opacity: 0.95;
}
