.overlay__container {
  position: fixed;
  inset: 0;

  width: 100vw;
  height: 100vh;

  background-color: transparent;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding-top: var(--pad-med);

  z-index: 6;
}

.edit__container {
  width: 98vw;
  max-width: 40rem;
  max-height: 90vh;

  background-color: #eee;
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
}
