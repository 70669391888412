.editor-container {
  position: relative;

  width: 100%;
  flex: 1;

  box-shadow: 0 0 5px 1.5px rgba(0, 0, 0, 0.1);

  /* overflow-y: scroll; */
}

.editor-container .ck-editor__editable {
  /* height: calc(var(--container-height) - 4rem); */
  min-height: 20rem;
  height: 50vh;

  font-size: var(--text-size-input-med);
  line-height: calc(var(--text-size-input-lrg) * 1.15);
}

.ck.ck-content ul,
.ck.ck-content ul li {
  list-style-type: inherit;
}

.ck.ck-content ul {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-content ol,
.ck.ck-content ol li {
  list-style-type: decimal;
}
.ck.ck-content ol {
  /* Default user agent stylesheet, you can change it to your needs. */
  padding-left: 40px;
}

.ck.ck-sticky-panel .ck-sticky-panel__content_sticky {
  /* position: absolute !important; */
}

.ck-powered-by {
  visibility: hidden;
  display: none;
}

@media (max-width: 640px) {
  .editor-container .ck-editor__editable {
    font-size: 16px;
  }
}

@media (max-width: 600px) {
  .editor-container .ck-editor__editable {
    /* height: calc(var(--container-height) - 4rem); */
    min-height: 15rem;

    font-size: 16px;
    /* line-height: 2rem; */
  }
}

@media (max-width: 500px) {
  .editor_submit-btn {
    padding: 0.4rem 2rem;

    /* font-size: 1.4rem; */
  }

  .editor-container .ck-editor__editable {
    /* height: calc(var(--container-height) - 4rem); */
    min-height: 15rem;

    /* font-size: 1.2rem; */
    /* line-height: 1.8rem; */
  }
}

@media (max-width: 400px) {
  .editor_submit-btn {
    padding: 0.2rem 1.2rem;

    /* font-size: 1.2rem; */
  }

  .editor-container .ck-editor__editable {
    /* height: calc(var(--container-height) - 4rem); */
    min-height: 10rem;

    /* font-size: 1.1rem; */
    /* line-height: 1.6rem; */
  }
}
