.form__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: calc(var(--gap-lrg) * 1.25);

  padding: var(--pad-modal);

  overflow-y: auto;
}

.field__container {
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: calc(var(--gap-sml) * 0.5);
}

.label__container {
  display: flex;
  align-items: center;
  gap: calc(var(--gap-sml) * 0.75);
}

.input__label {
  color: var(--color-purple) !important;
}

.icon {
  width: calc(var(--text-size-input-sml) * 1.25);
  height: calc(var(--text-size-input-sml) * 1.25);

  transform: scale(1.2);

  color: var(--color-purple);
}

.input__container {
  display: flex;
  align-items: center;
  gap: var(--gap-sml);
}
.url__input {
  width: auto !important;
  flex: 1;

  padding-top: calc(var(--pad-sml) * 0.25) !important;
  padding-bottom: calc(var(--pad-sml) * 0.25) !important;

  font-size: calc(var(--text-size-input-sml) * 0.85) !important;
  font-weight: 600;

  color: var(--color-blue) !important;
}

.url__input_disabled {
  cursor: not-allowed;
  background-color: #9999997e;

  color: #33333333 !important;
}

.url__input::placeholder {
  opacity: 0.6;
  font-weight: 500;
}

.checkbox__container {
  position: relative;

  --checkbox-width: calc(var(--text-size-input-med) * 1);
  --checkbox-height: calc(var(--text-size-input-med) * 1);
  --checkmark-width: calc(var(--text-size-input-sml) * 0.25);
  --checkmark-height: calc(var(--text-size-input-sml) * 0.6);
  --checkmark-border: calc(var(--text-size-input-sml) * 0.15);
}

.checkbox {
  position: absolute;
  inset: 0;

  opacity: 0;

  cursor: pointer;
}

.checkbox__label {
  display: block;

  width: var(--checkbox-width);
  height: var(--checkbox-height);

  /* border: 1px solid #333; */
  border-radius: 4px;

  box-shadow: 0 0 2px 1.4px rgba(0, 0, 0, 0.3);

  background: #fff;

  cursor: pointer;

  transition: all 0.3s;
}

.checkbox__label::after {
  content: '';

  position: absolute;

  left: 50%;
  top: 50%;

  width: var(--checkmark-width);
  height: var(--checkmark-height);
  border: solid #fff;
  border-width: 0 var(--checkmark-border) var(--checkmark-border) 0;

  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);

  display: none;
}

.checkbox:checked + .checkbox__label {
  background: var(--color-blue);
}

.checkbox:checked + .checkbox__label::after {
  display: block;

  animation: checkmark 0.2s ease-in forwards;
}

@keyframes checkmark {
  from {
    height: 0;
  }

  to {
    height: var(--checkmark-height);
  }
}

.checkbox:focus + .checkbox__label {
  box-shadow: 0 0 5px 4px var(--color-blue-shadow);
}

@media (hover: hover) {
  .checkbox__label:hover,
  .checkbox:hover + .checkbox__label {
    box-shadow: 0 0 5px 4px var(--color-blue-shadow);
  }

  .url__input_disabled:hover {
    box-shadow: 0 0 0 !important;
  }
}
