.backdrop {
  position: fixed;
  inset: 0;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.9);

  z-index: 10;

  display: flex;
  align-items: flex-start;
  justify-content: center;

  padding-top: var(--pad-lrg);
}

.modal__container {
  max-width: 30rem;
  width: 95vw;

  background-color: #eee;

  border-radius: 6px;

  display: flex;
  flex-direction: column;

  animation: slideIn 0.3s ease-out forwards;
}

.edit-modal__wrapper {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: calc(var(--pad-sml) * 0.5) var(--pad-med);
}

.title__container {
  background-color: var(--color-red-shade);

  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.content__container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--pad-sml) var(--pad-med);
}

.content__container p {
  font-size: var(--text-size-med);
  font-weight: 600;
  letter-spacing: 0.2px;
  color: #333;
}

.btns__container {
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: var(--pad-sml) var(--pad-med);
}

.btn {
  border: 2px solid var(--color-red-shade);
  border-radius: 3px;

  padding: calc(var(--pad-sml) * 0.3) calc(var(--pad-sml) * 0.5);

  font-weight: 600;
  color: var(--color-red-shade);

  transition: all 0.3s;
  cursor: pointer;
}

.confirm__btn {
  background-color: var(--color-red-shade);

  color: #eee;
}

.cancel__btn {
}

@keyframes slideIn {
  from {
    transform: translateY(-150%);
  }

  to {
    transform: translateY(0);
  }
}

@media (hover: hover) {
  .btn:hover {
    background-color: #fff;
  }

  .confirm__btn:hover {
    background-color: var(--color-red-shade-highlight);
  }
}
