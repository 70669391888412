.nav-container {
  width: 100%;
  list-style: none;

  display: flex;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  gap: var(--gap-lrg);

  margin-top: var(--margin-sml);
  padding: var(--pad-sml) var(--pad-sml);
}

.nav_link:link,
.nav_link:visited,
.nav_link:active {
  font-size: calc(var(--text-size-input-sml) * 0.95);
  text-decoration: none;
  color: var(--color-text-gray);
  letter-spacing: 1.25px;

  padding: calc(var(--pad-sml) * 0.2);
  padding-bottom: calc(var(--pad-sml) * 0.35);

  border-bottom: 2px solid var(--color-text-black);
  border-bottom-color: transparent;

  transition: all 0.3s;
}

.nav_link__active:link,
.nav_link__active:visited,
.nav_link__active:active {
  color: var(--color-text-black);
  color: var(--color-main-shade);
  font-weight: 600;

  border-bottom-color: var(--color-text-black);
  border-bottom-color: var(--color-main-shade);
}

@media (hover: hover) {
  .nav_link:hover {
    color: var(--color-text-black);
  }
}
